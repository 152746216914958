import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../../assets/Layout/MainLayout";
import { FaEllipses } from "../../../assets/Images/icon";
import { CouponWrapper } from "./style/Coupon";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast } from "react-toastify";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { couponTypeName, couponTypeOptions, userRoleOptions } from "../../../config/constant";

const maxDiscountPercentage = 100;
const CreateCoupon = () => {
  const location = useLocation();
  const couponType = location.state.couponType
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [addPromo, _addPromo] = useState(true);
  const [loading, _loading] = useState(false);

  const [type, _type] = useState("percentage");
  const [profile, _profile] = useState(couponType == couponTypeName.CREDITS ? userRoleOptions.Admin : "");
  const [durationVal, _durationVal] = useState("");
  const [dateRange, _dateRange] = useState(false);
  const [dateVal, _dateVal] = useState("");
  const [redeemVal, _redeemVal] = useState("");
  const [noRange, _noRange] = useState(false);

  //Plan
  const [plans, _plans] = useState([])
  const [filtereddata, _filtereddata] = useState([])
  const [planID, _planID] = useState('')
  const [planDetails, _planDetails] = useState('')

  // PromoCode
  const [promoCode, _promoCode] = useState('')
  const [usageCount, _usageCount] = useState(false)
  const [usageCountValue, _usageCountValue] = useState("")
  const [expiryDate, _expiryDate] = useState(false)
  const [expiryDateValue, _expiryDateValue] = useState("")
  const [active, _active] = useState(false)
  const [errorMessage, _errorMessage] = useState("")

  var currDate = new Date().toISOString().slice(0, 16);
  const [selectedElements, setSelectedElements] = useState([]);
  const [stripeProdId, setStripeProdId] = useState([]);
  const [publicAvailabilty, _publicAvailabilty] = useState(true);

  useEffect(() => {
    getPlans()
  }, [])

  //GETTING PLAN FOR SUBSCRIPTIONS OR CREDITS 
  const getPlans = () => {
    if (couponType === couponTypeName.SUBSCRIPTIONS) {
      API.get(APP_URLS.GET_PLANS + "?plan_type=year")
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            _plans(resp.data.plans)
            _filtereddata(resp.data.plans)
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            if (resp.data.data.message) {
              error_message = resp.data.data.message
            } else {
              {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
              }
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      API.get(`${APP_URLS.GET_PLANS}/credit_plans`)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            _plans(resp.data.items)
            _filtereddata(resp.data.items)
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            if (resp.data.data.message) {
              error_message = resp.data.data.message
            } else {
              {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
              }
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }

  const handleProme = (val) => {
    _addPromo(!addPromo)
    if (val === false) {
      _promoCode('')
      _usageCount(false)
      _expiryDate(false)
      _active(false)
      _usageCountValue('')
      _expiryDateValue('')
    }
  }

  // const handleProducts = (val) => {
  //   setChecked(!checked)
  //   if (val === false) {
  //     _planDetails('')
  //     _planID('')
  //   }
  // }

  const handlePublicAvail = () => {
    _publicAvailabilty(!publicAvailabilty)
  }

  const searchPlans = (value) => {
    let search1 = value.charAt(0).toLowerCase() + value.slice(1)
    let search2 = value.charAt(0).toUpperCase() + value.slice(1)
    let uniqueArr = [];
    if (value.length > 0) {
      let newData = [...plans]
      let val = newData.filter(newData => newData.name.includes(search1))
      let nval = newData.filter(newData => newData.name.includes(search2))
      val = [...val, ...nval]
      for (let i of val) {
        if (uniqueArr.indexOf(i) === -1) {
          uniqueArr.push(i);
        }
      }
      let finalval = uniqueArr.sort((a, b) => a.name.localeCompare(b.name))
      _filtereddata(finalval)
    }
    else {
      _filtereddata(plans)
    }
  }


  const formik = useFormik({
    initialValues: {
      name: "",
      type: "percentage",
      percentageOff: "",
      type_profile: "",
      duration: "",
      no_of_months: "",
      limitRange: "",
      dateRange: "",
      limitTimes: "",
      timesRange: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Coupon Name is required"),
      type: Yup.string().required("Select coupon discount type"),
      type_profile: couponType == couponTypeName.SUBSCRIPTIONS ? Yup.string().required("Select type of profile") : Yup.string(),
      percentageOff: Yup.number().required("Discount amount/percentage is required").when('type', {
        is: 'amount',
        then: Yup.number().integer("Decimal Values aren't allowed")
      }).when('type', {
        is: 'percentage',
        then: Yup.number().max(maxDiscountPercentage, "Percentage can't be greater than 100")
      }),
      duration: Yup.string().required("Coupon Duration is required"),
      no_of_months: Yup.string().ensure().when('duration', {
        is: 'repeating',
        then: Yup.string().required("Number of months is required")
      }),
      dateRange: Yup.string().ensure().when('limitRange', {
        is: true,
        then: Yup.string().required("Redeem Date is required")
      }),
      timesRange: Yup.number().when('limitTimes', {
        is: true,
        then: Yup.number().required("Count is required").max(4294967295, "Maximum value can't exceed 4294967295")
      }),
    }),
    onSubmit: (values) => {
      if (addPromo === true) {
        if (parseInt(redeemVal) < parseInt(usageCountValue)) {
          toast.error("Promo code redeem count can not be greater than rememption limit's redeem count", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          handleFormSubmit(values)
        }
      }
      else {
        handleFormSubmit(values)
      }
    },
  });
  const handleFormSubmit = (values) => {
    _loading(true)
    const data = {}
    data["name"] = values.name
    data["duration"] = values.duration
    data["profile_type"] = couponType == couponTypeName.CREDITS ? userRoleOptions.Admin : values.type_profile
    if (checked) {
      let newData = {}
      newData["products"] = stripeProdId  //array of stripe product id
      data["applies_to"] = newData
    }
    if (values.type === "percentage") {
      data["percent_off"] = parseFloat(values.percentageOff).toFixed(2)
    }
    if (values.type === "amount") {
      data["amount_off"] = values.percentageOff
    }
    if (values.duration === "repeating") {
      data["duration_in_months"] = values.no_of_months
    }
    if (values.limitRange === true) {
      data["redeem_by"] = moment(values.dateRange).parseZone('').format("YYYY-MM-DD HH:mm:ss");
    }
    if (values.limitTimes === true) {
      data["max_redemptions"] = values.timesRange
    }
    data["coupon_type"] = couponTypeOptions[couponType]
    if (couponType == couponTypeName.CREDITS) data["public_availability"] = publicAvailabilty ? 1 : 0
    if (checked && planID === "") {
      toast.error("Please choose a product plan", {
        position: toast.POSITION.TOP_RIGHT,
      });
      _loading(false)
    }
    else {
      if (!loading) {
        API.post(APP_URLS.ADD_COUPON, data)
          .then((res) => {
            const resp = res.data;
            _loading(false);
            if (resp.success === true) {
              if (addPromo === true && profile === '1') {
                savePromo(resp.data.item.id)
              }
              else {
                toast.success("Coupon has been created successfully.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/coupons");
                formik.resetForm();
              }
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            const resp = error.response;
            _loading(false);
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              if (resp.data.data.message) {
                error_message = resp.data.data.message
              } else {
                {
                  Object.keys(resp.data.data).map((error, index) => (
                    resp.data.data[error].map((errorVal, ind) => {
                      toast.error(errorVal, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    })
                  ));
                }
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            if (error_message !== '') {
              toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          });
      }
    }
  }

  const savePromo = (id) => {
    _loading(true)
    let data = {}
    data['coupon_id'] = id
    data['code'] = promoCode
    if (usageCount === true) {
      data['max_redemptions'] = usageCountValue
    }
    if (expiryDate === true) {
      data['expires_at'] = moment(expiryDateValue).parseZone('').format("YYYY-MM-DD HH:mm:ss")
    }
    data['active'] = active
    API.post(APP_URLS.ADD_PROMO, data)
      .then((res) => {
        const resp = res.data;
        _loading(false)
        if (resp.success === true) {
          toast.success("Coupon has been created and Promo Code has been added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/coupons");
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        _loading(false)
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if (resp.data.data.message) {
            error_message = resp.data.data.message
          } else {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  const validate = (val) => {
    let newVal = (val.indexOf(".") >= 0) ? (val.substr(0, val.indexOf(".")) + val.substr(val.indexOf("."), 3)) : val;
    if (newVal > maxDiscountPercentage) {
      return
    }
    formik.setFieldValue('percentageOff', newVal)
  }

  return (
    <>
      <CouponWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={() => navigate("/coupons")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <form className="auctionMainContainer" onSubmit={formik.handleSubmit}>
          <div className="headerContainer d-flex justify-content-center">
            <h1>{couponType == couponTypeName.SUBSCRIPTIONS ? 'CREATE SUBSCRIPTION COUPON' : 'CREATE CREDIT COUPON'}</h1>
          </div>
          <div className="actionContainer d-flex justify-content-end">
            <button className={(loading ? 'disabled' : '') + " btn"} type="submit" disabled={loading}>{loading ? 'Saving...' : 'Save'}</button>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 pt-2 mb-3">
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label htmlFor="name" className="form-label"><b>Name</b></label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="First purchase discount"
                      className="form-control"
                      maxlength="40"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label htmlFor="seller" className="label my-3"><b>Type</b></label>
                      <div className="col-md-12 mb-2 form-group">
                        <input
                          type="radio"
                          id="percentageDiscount"
                          name="type"
                          checked={formik.values.type === "percentage" ? true : false}
                          value="percentage"
                          className="ms-1"
                          onChange={() => {
                            _type("percentage");
                            formik.setFieldValue("type", "percentage");
                          }}
                        />
                        <label htmlFor="html" className="form-label ms-3">Percentage discount</label>
                      </div>
                      <div className="col-md-12 form-group">
                        <input
                          type="radio"
                          id="amountDiscount"
                          name="type"
                          value="amount"
                          className="ms-1"
                          onChange={() => {
                            _type("amount");
                            formik.setFieldValue("type", "amount");
                          }}
                        />
                        <label htmlFor="css" className="form-label ms-3">Amount discount</label>
                      </div>
                      {formik.touched.type && formik.errors.type ? <div className="error">{formik.errors.type}</div> : null}
                    </div>
                  </div>
                  {couponType == couponTypeName.CREDITS ? <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <div className="row">
                      <div className="switchBox d-flex">
                        <Switch
                          onChange={(e) => handlePublicAvail(e)}
                          checked={publicAvailabilty}
                          className="react-switch align-self-center"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          offColor="#D9D9D9"
                          onColor="#7589EC"
                        />
                        <label className="ps-3">Show publically</label>
                      </div>
                    </div>
                  </div> : null}
                  <div className="row mt-3">
                    <div className="col-md-6 form-group">
                      <div className="formInput">
                        <label htmlFor="percentage" className="form-label"><b>{type === "percentage" ? "Percentage Off" : "Discount amount"}</b></label>
                        <div className="input-group">
                          <input
                            type="number"
                            id="percentage"
                            name="percentageOff"
                            className="form-control"
                            value={formik.values.percentageOff}
                            onChange={(e) => {
                              if (type === 'percentage') {
                                validate(e.target.value)
                              } else {
                                formik.handleChange(e)
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                          <span className="input-group-text" id="off-percentage">{type === "percentage" ? "%" : "€"}</span>
                        </div>
                        {formik.touched.percentageOff && formik.errors.percentageOff ? <div className="error">{formik.errors.percentageOff}</div> : null}
                      </div>
                    </div>
                    <div className="col-md-6 form-group">
                      <div className="formInput">
                        <label htmlFor="duration" className="form-label"><b>Duration</b> </label>
                        <select name="duration" id="duration" value={formik.values.duration}
                          onChange={(e) => {
                            formik.handleChange(e)
                            _durationVal(e.target.value)
                          }}
                          onBlur={formik.handleBlur}
                          className="form-control">
                          <option hidden>Select</option>
                          <option value="forever">Forever</option>
                          <option value="once">Once</option>
                          <option value="repeating">Repeating</option>
                        </select>
                      </div>
                      {formik.touched.duration && formik.errors.duration ? <div className="error">{formik.errors.duration}</div> : null}
                    </div>
                    {durationVal === "repeating" ?
                      <div className="col-md-12 form-group mt-3">
                        <label htmlFor="no_of_months" className="form-label"><b>Number of months</b> </label>
                        <div className="input-group">
                          <input
                            type="number"
                            id="no_of_months"
                            name="no_of_months"
                            className="form-control"
                            value={formik.values.no_of_months}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span className="input-group-text" id="off-percentage">Months</span>
                        </div>
                        {formik.touched.no_of_months && formik.errors.no_of_months ? <div className="error">{formik.errors.no_of_months}</div> : null}
                      </div>
                      : ""}
                  </div>

                  {couponType == couponTypeName.SUBSCRIPTIONS ? <div className="col-md-12 form-group mt-3">
                    <label htmlFor="type_profile" className="form-label"><b>Type of Profile</b> </label>
                    <select name="type_profile" id="type_profile" value={couponType == couponTypeName.CREDITS ? userRoleOptions.Admin : formik.values.type_profile}
                      onChange={(e) => {
                        formik.handleChange(e)
                        _profile(e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                      disabled={couponType == couponTypeName.CREDITS}
                      className="form-control">
                      <option hidden>Select</option>
                      <option value={userRoleOptions.Admin}>Admin</option>
                      <option value={userRoleOptions.Nomad}>Nomad</option>
                    </select>
                    {formik.touched.type_profile && formik.errors.type_profile ? <div className="error">{formik.errors.type_profile}</div> : null}
                  </div> : null}

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <label htmlFor="redemptionLimits" className="col-md-12 form-label mt-2"><b>Redemption limits</b></label>
                      <div className="col-md-12 form-group my-1 d-flex">
                        <input type="checkbox" className="align-self-center me-3 ms-1"
                          id="limitRange"
                          name="limitRange"
                          value={formik.values.limitRange}
                          onChange={(e) => {
                            formik.handleChange(e)
                            _dateRange(!dateRange)
                            _dateVal('')
                          }}
                          onBlur={formik.handleBlur} />
                        <label htmlFor="vehicle1"> Limit the date range when customers can redeem this coupon</label>
                      </div>
                      {dateRange ?
                        <>
                          <div className="col-md-12 form-group my-1 d-flex align-items-center">
                            <div className="promoInput d-flex align-items-center date-time-picker">
                              <span className="fieldTitle ms-2 me-2"> Redeem By</span>
                              <Datetime
                                name="dateRange"
                                id="dateRange"
                                dateFormat="DD-MM-YYYY"
                                inputProps={{
                                  placeholder: "Select a date and time"
                                }}
                                isValidDate={(date) => { return date.isAfter(currDate) }}
                                value={formik.values.dateRange}
                                onChange={
                                  (e) => {
                                    formik.setFieldValue(
                                      "dateRange", e
                                    );
                                    _dateVal(e)
                                  }
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                          {formik.touched.dateRange && formik.errors.dateRange ? <div className="error">{formik.errors.dateRange}</div> : null}
                        </>
                        : ''}
                      <div className="col-md-12 form-group my-1 d-flex">
                        <input type="checkbox" className="align-self-center me-3 ms-1"
                          id="limitTimes"
                          name="limitTimes"
                          value={formik.values.limitTimes}
                          onChange={(e) => {
                            formik.handleChange(e)
                            _noRange(!noRange)
                            _redeemVal('')
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="limitTimes"> Limit the total number of times this coupon can be redeemed</label>
                      </div>
                      {noRange ?
                        <>
                          <div className="col-md-12 form-group my-1 d-flex align-items-center">
                            <div className="promoInput">
                              <input type="number" className="fieldBox border-right"
                                id="timesRange"
                                name="timesRange"
                                placeholder="1"
                                value={formik.values.timesRange}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                  _redeemVal(e.target.value)
                                }}
                                onBlur={formik.handleBlur} />
                              <span className="fieldTitle mx-2">times</span>
                            </div>
                          </div>
                          {formik.touched.timesRange && formik.errors.timesRange ? <div className="error">{formik.errors.timesRange}</div> : null}
                        </>
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 pt-2 mb-5">
                <div className="switchBox d-flex">
                  {/* Code commented due to requirement for products */}
                  {/* <Switch
                    onChange={(e) => handleProducts(e)}
                    checked={checked}
                    disabled
                    className="disabled react-switch align-self-center"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor="#D9D9D9"
                    onColor="#7589EC"
                  /> */}
                  <label className="fw-bold">Apply to specific products</label>
                </div>
                {checked ?
                  <>
                    <div className="cardContainer mt-3 mb-2 ">
                      <input type="text" className="form-control" placeholder="Find a Product" onChange={(e) => searchPlans(e.target.value)} />
                    </div>
                    <div className="table-wrapper">
                      <table className="custom-table">
                        <thead>
                          <tr>
                            <th className="center-text">Select</th>
                            <th className="center-text">Plan Name</th>
                            <th className="center-text">Price ID</th>
                            <th className="center-text">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filtereddata?.length > 0 ? (
                            filtereddata.map((item, index) => (
                              <tr
                                key={index}
                                className={` ${selectedElements.includes(item) ? "selected" : ""}`}
                                onClick={() => {
                                  _planID(item.stripe_product_id);
                                  _planDetails(item);
                                }}
                              >
                                <td className="center-text">
                                  <input
                                    type="checkbox"
                                    checked={selectedElements.includes(item)} // Check if the item is in the selectedElements array
                                    onChange={() => {
                                      // Toggle the selection status of the item
                                      const updatedSelection = selectedElements.includes(item)
                                        ? selectedElements.filter((el) => el !== item) // Remove the item if it's already selected
                                        : [...selectedElements, item]; // Add the item if it's not selected
                                      setSelectedElements(updatedSelection);
                                      setStripeProdId(updatedSelection.map(s => s.stripe_product_id));
                                    }}
                                  />
                                </td>
                                <td className="center-text">{item.name}</td>
                                <td className="center-text">{item.prices[0].id}</td>
                                <td className="center-text">{new Intl.NumberFormat("de-DE", { currency: 'EUR' }).format(item.prices[0].unit_amount / 100) + `${couponType === couponTypeName.SUBSCRIPTIONS ? " € / year" : " €"}`}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" className="norecord">
                                No plan found..
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </> : ''}
              </div>
            </div>
            {profile === '1' ?
              <div className="row">
                <div className="col-lg-5 pt-2 mb-3">
                  <div className="mb-3">
                    <b className="form-label">Promo Codes</b>
                  </div>
                  <div className="switchBox d-flex">
                    <Switch
                      onChange={(e) => handleProme(e)}
                      checked={addPromo}
                      className="react-switch align-self-center"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#D9D9D9"
                      onColor="#7589EC"
                    />
                    <label className="ps-3">Add Promo Code</label>
                  </div>
                  {addPromo ?
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <label htmlFor="redemptionLimits" className="col-md-12 form-label mt-2"><b>Code</b></label>
                          <input type="text" className="form-control"
                            placeholder="FRIENDS20"
                            maxlength="10"
                            title="This code is case-insensitive and must be unique across all active promotion codes htmlFor any customer. If left blank, we will generate one automatically"
                            onChange={(e) => _promoCode(e.target.value)} />
                        </div>
                        <div className="col-md-12 form-group my-1 d-flex">
                          <input type="checkbox" className="align-self-center me-3 ms-1"
                            id="limitRangeforPromo"
                            name="limitRangeforPromo"
                            value={usageCount}
                            onChange={() => _usageCount(!usageCount)}
                          />
                          <label htmlFor="vehicle1"> Limit the number of times this code can be redeemed</label>
                        </div>
                        {usageCount === true ?
                          <div className="col-md-12 form-group my-1 d-flex align-items-center">
                            <div className="promoInput">
                              <input type="number" className="fieldBox border-right"
                                id="timesRange"
                                name="timesRange"
                                placeholder="1"
                                value={usageCountValue}
                                onChange={(e) => _usageCountValue(e.target.value)} />
                              <span className="fieldTitle mx-2">times</span>
                            </div>
                          </div>
                          : ''}
                        <div className="col-md-12 form-group my-1 d-flex">
                          <input type="checkbox" className="align-self-center me-3 ms-1"
                            id="expiryDatecheckbox"
                            name="expiryDatecheckbox"
                            value={expiryDate}
                            onChange={() => _expiryDate(!expiryDate)}
                          />
                          <label htmlFor="vehicle1"> Add an expiry date</label>
                        </div>
                        {expiryDate === true ?
                          <div className="col-md-12 form-group my-1 d-flex flex-column">
                            <div className="promoInput promoDate d-flex align-items-center date-time-picker">
                              <span className="fieldTitle ms-2 me-2"> Expires at</span>
                              <Datetime
                                dateFormat="DD-MM-YYYY"
                                name="expiryDate"
                                id="expiryDate"
                                inputProps={{
                                  placeholder: "Select a date and time"
                                }}
                                isValidDate={(date) => {
                                  if (dateVal) {
                                    return date.isAfter(currDate) && date.isBefore(dateVal)
                                  }
                                  else {
                                    return date.isAfter(currDate)
                                  }
                                }}
                                value={expiryDateValue}
                                onChange={(e) => {
                                  _expiryDateValue(e)
                                }}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className="d-block">
                              {errorMessage !== "" && errorMessage !== undefined ? <div className="error">{errorMessage}</div> : null}
                            </div>
                          </div>
                          : ''}
                        <div className="col-md-12 form-group my-1 d-flex">
                          <input type="checkbox" className="align-self-center me-3 ms-1"
                            id="active"
                            name="active"
                            value={active}
                            onChange={() => _active(!active)}
                          />
                          <label htmlFor="limitOrdersforPromo"> Active</label>
                        </div>
                      </div>
                    </div>
                    : ""}
                </div>
              </div>
              : ''}
          </div>
        </form>
      </CouponWrapper>
    </>
  );
};

export default CreateCoupon;
