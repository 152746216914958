import React, { useEffect } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import option from "../../../assets/Images/icons/faDotsVertical.svg";
import user from "../../../assets/Images/icons/user-id.png";
import { DashboardWrapper } from "./style/dashboard";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import PieChartComponent from "./Components/pieChart";
import PercentageBox from "./Components/percentageBox";
import BarChartComponent from "./Components/barChart";
import AreaChartComponent from "./Components/areaChart";

const Dashboard = () => {

  const subsidiary = localStorage.getItem('subsidiary_id')

  useEffect(()=>{
    getCountries();
    getLanguages();
    getproductType();
    getUserPermissions();
    localStorage.setItem("Translation","Languages")
  },[])

  const getCountries =() =>{
    API.get(APP_URLS.COUNTRY_LIST+"?continent=all")
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        let languages = resp.data.items
        localStorage.setItem("countries",JSON.stringify(languages))
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const getLanguages =() =>{
    API.get(APP_URLS.ADMIN_LANGUAGES+`?limited_data=true`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        let languages = resp.data.items
        localStorage.setItem("languages",JSON.stringify(languages))
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const getproductType = () =>{
    API.get(APP_URLS.PRODUCT_TYPES + "?with_category=true")
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        localStorage.setItem("productType",JSON.stringify(resp.data.items))
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data?.email !== undefined) {
          {Object.keys(resp.data.data.email).map((error, index) => (
              error_message = resp.data.data.email[error]
          ))}
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const getUserPermissions = () =>{
    API.get(APP_URLS.USER_PERMISSION + `/${subsidiary}/permission`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        localStorage.setItem("permissions",JSON.stringify(resp.data.items.filter(o=>o.type!==2)))
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data?.email !== undefined) {
          {Object.keys(resp.data.data.email).map((error, index) => (
              error_message = resp.data.data.email[error]
          ))}
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }


  return (
    <>
      <DashboardWrapper>
      <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="mb-2">
              <h1>WELCOME TO DASHBOARD</h1>
            </div>
          </div>
          {/* <div className="pieChartContainer">
            <PieChartComponent heading="Subscriptions" value={684} color="#6C5DD3"/>
            <PieChartComponent pClass="ms-4" heading="Classic ads" value={1.546} color="#FD8539"/>
            <PieChartComponent pClass="ms-4" heading="Auction ads " value={5.732} color="#2ED480"/>
            <PieChartComponent pClass="ms-4" heading="Sales products" value={232.700} color="#FE6D8E"/>
            <PieChartComponent pClass="ms-4" heading="Users" value={684} color="#2ED480"/>
            <PieChartComponent pClass="ms-4" heading="Canceled subscription" value={90} color="#FD8539"/>
            <PieChartComponent pClass="ms-4" heading="User Disable" value={684} color="#6C5DD3"/>
            <PieChartComponent pClass="ms-4" heading="Complaints" value={5.732} color="#2ED480"/>
          </div>
          <div className="pieChartContainer">
            <PercentageBox heading="Subscriptions" value={50}/>
            <PercentageBox pClass="ms-4" heading="Classic ads" value={-10}/>
            <PercentageBox pClass="ms-4" heading="Auction ads " value={33}/>
            <PercentageBox pClass="ms-4" heading="Sales products" value={-87}/>
            <PercentageBox pClass="ms-4" heading="Users" value={23}/>
            <PercentageBox pClass="ms-4" heading="Canceled subscription" value={65}/>
            <PercentageBox pClass="ms-4" heading="User Disable" value={23}/>
            <PercentageBox pClass="ms-4" heading="Complaints" value={-10}/>
          </div>
          <div className="row mt-5">
            <div className="col-xl-6">
              <BarChartComponent />
            </div>
            <div className="col-xl-6">
              <AreaChartComponent />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 px-4">
              <div className="listHeader">
                <span className="header">Most seller</span>
                <img src={option} />
              </div>
              <div className="p-2">
                {Array(5).fill(undefined).map((item,index)=>{
                  return <div className="d-flex flex-row align-items-center userImage mt-2 contentBox" key={index}>
                  <img src={user}/>
                  <div className="d-flex flex-column ms-4">
                    <span className="fw-bold">Benny Chagur</span>
                    <span className="valBox">Member</span>
                  </div>
                </div>
                })}
              </div>
            </div>
            <div className="col-md-4 px-4">
              <div className="listHeader">
                <span className="header">Most sales products</span>
                <img src={option} />
              </div>
              <div className="p-2">
              {Array(5).fill(undefined).map((item,index)=>{
                  return <div className="d-flex flex-column justify-content-center mt-2 contentBox" key={index}>
                    <span className="fw-bold">Cars</span>
                    <span className="valBox">12,000</span>
                </div>
                })}
              </div>
            </div>
            <div className="col-md-4 px-4">
              <div className="listHeader">
                <span className="header">Most products visited (click)</span>
                <img src={option} />
              </div>
              <div className="p-2">
              {Array(5).fill(undefined).map((item,index)=>{
                  return <div className="d-flex flex-column justify-content-center mt-2 contentBox" key={index}>
                    <span className="fw-bold">Cars</span>
                    <span className="valBox">12,000</span>
                </div>
                })}
              </div>
            </div>
          </div> */}
        </div>
      </DashboardWrapper>
    </>
  );
};

export default Dashboard;
