import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import edit from "../../../../assets/Images/image/edit.png";
import waste from "../../../../assets/Images/image/Waste.png";
import { NavLink, useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast } from "react-toastify";
import Pagination from "../../../../assets/SharedComponents/Pagination";
import search from "../../../../assets/Images/icons/faSearchLite.svg";
import SideTranslate from "../../components/SideTranslate";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";
import { TableWrapper } from "../../style/table";
import SearchKey from "../../../../assets/SharedComponents/Search/SearchKey";
import { getName } from "../../../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";

const ModalItems = () => {
  const [data, _data] = useState([]);
  const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [dataDeleted, _dataDeleted] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, _totalPage] = useState(0);
  const [total, _total] = useState(0);

  const [products, _products] = useState(JSON.parse(localStorage.getItem("productType")));
  const [productValue, _productValue] = useState("");
  const [searchkeyword, _searchkeyword] = useState("");
  const [filters, _filters] = useState(false);

  const [loading, _loading] = useState(false);

  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let vehicleManagementAccess = userAccess.find(o => o.slug === "vehicle_management")


  let navigate = useNavigate();

  useEffect(() => {
    getModels();
    _dataDeleted(false);
  }, [dataDeleted, page, productValue, searchkeyword]);

  const getModels = () => {
    _loading(true);
    API.get(APP_URLS.LIST_MODEL_ITEMS + `?page=${page}&filter[name]=${searchkeyword}&filter[product_type_id]=${productValue}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _loading(false);
          _total(resp.data.total);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_MODEL_ITEMS + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Model Item has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (searchValue) => {
    _searchkeyword(searchValue)
    if (searchValue !== "") {
      _totalPage(0)
      setPage(1);
    }
  }

  const clearFilter = () => {
    _filters(true);
    handleSearchChange('');
    _productValue("");
    if (productValue !== "" || searchkeyword !== "") {
      setPage(1)
      _totalPage(0)
    }
  };
  return (
    <>
      <TableWrapper>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
            <h4 className="translation text-center mb-3">VEHICLES <br/> MANAGEMENT</h4>
            <SideTranslate />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
            <div className="headerContainer mb-3">
              <h1>MODEL ITEMS</h1>
            </div>
            <div className="d-flex justify-content-between add-info">
              <div className="add-depertment d-flex justify-content-end mb-2">
                {vehicleManagementAccess.permissions.find((o) => o.slug === "add") ? (
                  <div>
                    <NavLink to="/vehicles-management/create-vehicle-model-items">
                      <button className="btn">+ Add</button>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-between mb-2 gap-2 select-info align-items-center">
                <select
                  name="productType"
                  id="productType"
                  className="filterBox"
                  onChange={(e) => {
                    setPage(1);
                    _totalPage(0)
                    _productValue(e.target.value);
                  }}
                  value={productValue}
                >
                  <option value="">Product Type</option>
                  {products.map((item, index) => {
                    return (
                      <option value={item.product_categories[0].pivot.product_type_id} key={index}>
                        {item.item_name}
                      </option>
                    );
                  })}
                </select>
                <SearchKey onSearchClick={(search) => handleSearchChange(search)}
                  filters={filters}
                  _filters={_filters}
                />
                <Tooltip title="Clear Filter" placement="bottom">
                  <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                </Tooltip>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
                <div className="justify-content-end mt-1 me-2">
                    <span className='text-start'>Total Records : <b>{total}</b></span>
                </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th>ID</th>
                    <th>Name</th>
                    <th>Brand Name</th>
                    <th>Product Type</th>
                    <th>Updated by</th>
                    <th>Created by</th>
                    {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <th>Action</th> : ""}
                  </tr>
                </thead>
                {loading ?
                  <div className='d-flex justify-content-center three-dot'>
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                  :
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => {
                        let createdBy = getName(item.create_activity_log, item.update_activity_log);
                        let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                        return (
                          <tr className="table-row" key={index}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.model_car.brand_car.name}</td>
                            <td>{item.model_car.brand_car.product_type.item_name}</td>
                            <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                            <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                            {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? (
                              <td className="action">
                                {vehicleManagementAccess.permissions.find((o) => o.slug === "update") ? (
                                  <button className=" me-2" onClick={() => navigate(`/vehicles-management/edit-vehicle-model-items/${item.id}`)}>
                                    <img src={edit} alt="f" />
                                  </button>
                                ) : (
                                  ""
                                )}
                                {vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <img src={waste} alt="" role="button" onClick={() => removeType(item.id)} /> : ""}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecord />
                    )}
                  </tbody>
                }
              </table>
            </div>
            {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default ModalItems;
