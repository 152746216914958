import React, { useEffect, useRef, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { APP_URLS } from '../../../api/url';
import API from '../../../api/axios';
import { toast } from 'react-toastify';
import Pagination from '../../../assets/SharedComponents/Pagination';
import NoRecord from '../../../assets/SharedComponents/Table/NoRecord';
import SearchKey from '../../../assets/SharedComponents/Search/SearchKey';
import { FaFilterIcon } from '../../../assets/Images/icon';
import { HotdealStyleWrapper } from '../style/hotdealsStyle';
import ApprovalPopup from './ApprovalPopup';
import { getOverflowState, truncateText } from '../../../core/utils';
import { actionTypeOptions, approvalStatus, penaltyType, refundRequestType, refundRequestTypeOptions, refundRequestTypeText, requestType, userTypeTypo } from '../../../config/constant';
import faHide from "../../../assets/Images/icons/svgIcon/FaHide.svg"
import { Tooltip } from '@mui/material';
import RecordCount from '../../../assets/SharedComponents/RecordCount/RecordCount';
import BuyerCustomersFilter from '../../../assets/SharedComponents/Filters/BuyerCustomersFilter';
import SellerCustomersFilter from '../../../assets/SharedComponents/Filters/SellerCustomersFilter';
import StaticFilter from '../../../assets/SharedComponents/Filters/StaticFilter';
import moment from 'moment';

const actionTypeList = Object.keys(actionTypeOptions).map((key) => ({ name: key, value: actionTypeOptions[key] }))
const refundRequestTypeList = Object.keys(refundRequestTypeOptions).map((key) => ({ name: key, value: refundRequestTypeOptions[key] }))

const CancelReservationRequest = () => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, _data] = useState([]);
    const [searchkeyword, _searchkeyword] = useState(query.get('keyword') !== null ? query.get('keyword') : "");
    const [page, setPage] = useState(query.get('page') !== null ? parseInt(query.get('page')) : 1);
    const [total, _total] = useState(0);
    const [totalPage, _totalPage] = useState(0);
    const [loaded, _loaded] = useState(false);
    const [popup, _popup] = useState(false)
    const [approved, _approved] = useState(false)
    const [requestedById, _requestedById] = useState('');
    const [requestId, _requestId] = useState('');
    const [show, _show] = useState('')
    const [countValue, _countValue] = useState(query.get('per_page') !== null ? parseInt(query.get('per_page')) : "")
    const [filters, _filters] = useState(false)
    const [buyerID, _buyerID] = useState("");
    const [sellerID, _sellerID] = useState("");
    const [searchBuyerKeyword, _searchBuyerKeyword] = useState(query.get('sub_keyword') !== null ? query.get('sub_keyword') : "");
    const [searchSellerKeyword, _searchSellerKeyword] = useState(query.get('sub_keyword') !== null ? query.get('sub_keyword') : "");
    const [buyer, setBuyer] = useState(null);
    const [seller, setSeller] = useState(null);
    const [actionType, _actionType] = useState('');
    const [showReason, _showReason] = useState('')
    const [overflowStates, setOverflowStates] = useState({});
    const [isReject, _isReject] = useState(false);
    const [refundType, _refundType] = useState('');
    const [refundAmount, _refundAmount] = useState('');
    const refs = useRef({});

    useEffect(() => {
        const apiurl = getRestUrl()
        const getSubscriptions = () => {
            _loaded(true)
            API.get(`${APP_URLS.HOTDEAL_CANCEL_RESERVATION}/requests?${apiurl}`)
                .then(res => {
                    const resp = res.data;
                    if (resp.success === true) {
                        _data(resp.data.data)
                        _total(resp.data.total)
                        _totalPage(resp.data.last_page)
                        _loaded(false)
                    }
                    else {
                        toast.error(resp.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        _loaded(false)
                    }
                })
                .catch(function (error) {
                    _loaded(false)
                    const resp = error.response;
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {
                            Object.keys(resp.data.errors).map(
                                (error, index) => (error_message = resp.data.errors[error][0])
                            );
                        }
                    } else if (resp.data.data?.error !== undefined) {
                        error_message = resp.data.data.error;
                    } else if (resp.data?.error !== undefined) {
                        error_message = resp.data.error
                    } else {
                        error_message = resp.data.message
                    }
                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                });
        }
        getSubscriptions()
    }, [page, countValue, approved, searchkeyword, buyer, seller, actionType])

    //for showing reason comments
    const handleShowCancellationReason = (id) => {
        if (show) {
            _showReason('');
            _show(id === show ? '' : id);
        } else {
            _showReason('');
            _show(id);
        }
    }

    //for showing approval or rejection reason comments
    const handleShowRejectionReason = (id) => {
        if (showReason) {
            _show('');
            _showReason(id === showReason ? '' : id);
        } else {
            _show('');
            _showReason(id);

        }
    }

    // function to get name and link
    function getNames(data) {
        const getNameAndLink = (person, isPro) => {
            const name = (person.first_name || person.last_name)
                ? `${person.first_name} ${person.last_name}`.trim()
                : person.name;

            const link = isPro ? `/company-details/${person.id}` : `/detail-member/${person.id}`;

            return { name, link, isPro };
        };

        // Extract the seller name and link
        const sellerInfo = data.seller_sub
            ? getNameAndLink(data.seller_sub, true)
            : getNameAndLink(data.seller_user, false);

        // Extract the buyer name and link
        const buyerInfo = data.buyer_sub
            ? getNameAndLink(data.buyer_sub, true)
            : getNameAndLink(data.buyer_user, false);

        // Return the seller and buyer info
        return { sellerInfo, buyerInfo };
    }

    //handles popup for history
    const handleApprovalPopup = (cancelRequest, rejectValue = false) => {
        _popup(true);
        _requestedById(cancelRequest?.requested_by);
        _requestId(cancelRequest?.id);
        _isReject(rejectValue);
        _refundAmount(cancelRequest.reservation_amount);
    }

    const handleSearchChange = (func, searchValue) => {
        func(searchValue);
        if ((func === _countValue && searchValue !== countValue) || (func === _searchkeyword && searchValue !== searchkeyword)) {
            if (searchValue !== "") {
                _totalPage(0)
                setPage(1);
            }
        }
    };

    //removing all filters
    const clearFilter = () => {
        _filters(true)
        setSeller(null)
        setBuyer(null)
        _buyerID('')
        _sellerID('')
        handleSearchChange(_countValue, '');
        handleSearchChange(_searchkeyword, '');
        if (searchkeyword !== "" || countValue !== "") {
            _totalPage(0)
            setPage(1);
        }
        for (var value of query.keys()) {
            const param = searchParams.get(value);
            if (param) {
                searchParams.delete(value);
                setSearchParams(searchParams);
            }
        }
    };

    //filter params
    function getRestUrl() {
        let urlParams = {};

        if (countValue) urlParams['per_page'] = countValue;
        if (searchkeyword) urlParams['keyword'] = searchkeyword;
        if (page > 1) urlParams['page'] = page;
        if (actionType) urlParams['filter[status]'] = actionType;
        // Add buyer and seller specific params
        if (buyer) {
            if (buyer.source === userTypeTypo.USER) {
                urlParams['filter[buyer_user_id]'] = buyer.value;
            } else if (buyer.source === userTypeTypo.SUBSIDIARY) {
                urlParams['filter[buyer_sub_id]'] = buyer.value;
            }
        }

        if (seller) {
            if (seller.source === userTypeTypo.USER) {
                urlParams['filter[seller_user_id]'] = seller.value;
            } else if (seller.source === userTypeTypo.SUBSIDIARY) {
                urlParams['filter[seller_sub_id]'] = seller.value;
            }
        }

        const restUrl = new URLSearchParams(urlParams);
        return restUrl;
    }

    //setting buyer value
    const setBuyerValue = (item) => {
        setBuyer(item);
        _buyerID(item.value);
        _totalPage(0)
        setPage(1);
        handleSearchChange(_searchkeyword, '');
    }

    //setting seller value
    const setSellerValue = (item) => {
        setSeller(item);
        _sellerID(item.value);
        _totalPage(0)
        setPage(1);
        handleSearchChange(_searchkeyword, '');
    }

    //getting total overflow and ellipses status after the data is rendered.
    useEffect(() => {
        const totalEllipsisData = getOverflowState(refs.current)
        setOverflowStates(totalEllipsisData);
    }, [data]);

    return (
        <>
            <HotdealStyleWrapper>
                <div className="auctionMainContainer">
                    <div className="row mt-4">
                        <div className="col-md-12 text-align-center">
                            <h1>CANCEL HOTDEAL RESERVATION REQUEST</h1>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between info-wrap mt-3 mb-2">
                        <div className="justify-content-end mt-1 me-2">
                            <span className='text-start'>Total Records : <b>{total}</b></span>
                        </div>
                        <div className="justify-content-end mt-4 d-flex gap-2 mb-2 select-info align-items-center">
                            <SellerCustomersFilter _filters={_filters} filters={filters} setSeller={(val) => setSellerValue(val)} seller={sellerID} searchSellerKeyword={searchSellerKeyword} _searchSellerKeyword={_searchSellerKeyword} type={'seller'} />
                            <BuyerCustomersFilter _filters={_filters} filters={filters} setBuyer={(val) => setBuyerValue(val)} buyer={buyerID} searchBuyerKeyword={searchBuyerKeyword} _searchBuyerKeyword={_searchBuyerKeyword} type={'buyer'} />
                            <SearchKey
                                onSearchClick={(search) => handleSearchChange(_searchkeyword, search)}
                                id="searchData"
                                placeholder="Search by Vehicle title"
                                val={searchkeyword}
                                filters={filters}
                                _filters={_filters} />
                            <StaticFilter placeholder='Action Type' data={actionTypeList} handleChange={(e) => {
                                _actionType(e.target.value)
                                _totalPage(0)
                                setPage(1);
                            }} value={actionType} />
                            {/* <StaticFilter placeholder='Refund request Type' data={refundRequestTypeList} handleChange={(e) => {
                                _refundType(e.target.value)
                                _totalPage(0)
                                setPage(1);
                            }} value={refundType} /> */}
                            <RecordCount
                                onSearchClick={(search) => handleSearchChange(_countValue, search)}
                                id="pageCount"
                                val={countValue}
                                filters={filters}
                                _filters={_filters} />
                            <Tooltip title="Clear Filter" placement="bottom">
                                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="tableContainer">
                        <table className="auctionTable">
                            <thead className="tableHead">
                                <tr className="table-row">
                                    <th className="clipboard">ID</th>
                                    <th className="clipboard">
                                        Seller name
                                    </th>
                                    <th>Buyer name</th>
                                    <th>Vehicle details</th>
                                    <th>
                                        Sale price<br />
                                        Reservation amount
                                    </th>
                                    <th>Reservation cancellation reason</th>
                                    <th>Requested By</th>
                                    <th>Approval</th>
                                    <th>Rejection reason</th>
                                    <th>Cancellation timeline<br />(Pre Sale/Post Sale)</th>
                                    <th className="w80">Action</th>
                                </tr>
                            </thead>
                            {loaded ?
                                <div className='d-flex justify-content-center three-dot'>
                                    <ThreeDots
                                        height="150"
                                        width="150"
                                        radius="9"
                                        color="#637df4"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                                : <tbody>
                                    {data.length > 0 ?
                                        data.map((cancelRequest) => {
                                            const { sellerInfo, buyerInfo, isPro } = getNames(cancelRequest);
                                            const refundType = (cancelRequest.refund_request_type == refundRequestType.PRE_SALE) ? refundRequestTypeText.PRE_SALE : ((cancelRequest.refund_request_type == refundRequestType.POST_SALE) ? refundRequestTypeText.POST_SALE : '-')
                                            return (
                                                <>
                                                    <tr className={(cancelRequest.deleted_at ? "deletedRow" : "") + " table-row"} key={cancelRequest.id}>
                                                        <td className="clipboard">{cancelRequest.id}</td>
                                                        <td>
                                                            <a href={sellerInfo.link} className="link" title={sellerInfo.name} target='_blank'>
                                                                {sellerInfo.name}
                                                            </a><br />
                                                            <b>{sellerInfo.isPro ? "Pro" : "Private"}</b>
                                                        </td>
                                                        <td>
                                                            <a href={buyerInfo.link} className="link" title={buyerInfo.name} target='_blank'>
                                                                {buyerInfo.name}
                                                            </a><br />
                                                            <b>{buyerInfo.isPro ? "Pro" : "Private"}</b>
                                                        </td>
                                                        <td
                                                            onClick={() => window.open(`${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/en/hot-deal/${cancelRequest.ad_vehicle.id}`, "_blank")}
                                                            className="link">
                                                            {cancelRequest?.owner_vehicle?.full_title}
                                                        </td>
                                                        <td>€ {cancelRequest.sale_price} <br />€ {cancelRequest.reservation_amount}</td>
                                                        <td className="w-250">
                                                            {cancelRequest.reason ?
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <span className="message-text" ref={(el) => (refs.current[`cancellation-reason-${cancelRequest.id}`] = el)} title={cancelRequest.reason}>{cancelRequest.reason}</span>
                                                                    {overflowStates[`cancellation-reason-${cancelRequest.id}`] ? <img src={faHide} alt="show" className={(show === cancelRequest.id ? "close" : "open") + " dropbtn ms-2"} onClick={() => handleShowCancellationReason(cancelRequest.id)} /> : null}
                                                                </div>
                                                                : "-"}
                                                        </td>
                                                        <td>{cancelRequest.requested_by === requestType.SELLER ? "Seller" : (cancelRequest.requested_by === requestType.BUYER ? "Buyer" : '-')}</td>
                                                        <td>
                                                            {!!cancelRequest.status ? (
                                                                cancelRequest.status === approvalStatus.ACCEPT && cancelRequest.penalty === penaltyType.WITH_PENALTY ? "Approved with penalty" :
                                                                    cancelRequest.status === approvalStatus.ACCEPT && cancelRequest.penalty === penaltyType.WITHOUT_PENALTY ? "Approved without penalty" :
                                                                        '-'
                                                            ) : '-'}
                                                        </td>
                                                        <td>
                                                            {cancelRequest.admin_reason ?
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <span className="message-text" ref={(el) => (refs.current[`rejection-reason-${cancelRequest.id}`] = el)} title={cancelRequest.admin_reason}>{cancelRequest.admin_reason}</span>
                                                                    {overflowStates[`rejection-reason-${cancelRequest.id}`] ? <img src={faHide} alt="show" className={(showReason === cancelRequest.id ? "close" : "open") + " dropbtn ms-2"} onClick={() => handleShowRejectionReason(cancelRequest.id)} /> : null}
                                                                </div>
                                                                : "-"}
                                                        </td>
                                                        <td>
                                                            <span className="d-block">{moment(cancelRequest.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</span><br />
                                                            {refundType}</td>
                                                        <td className="w24">
                                                            <div className="access-btn">
                                                                {cancelRequest.status === null && (
                                                                    <>
                                                                        <button className="accept" onClick={() => handleApprovalPopup(cancelRequest)}>Accept</button>
                                                                        <button className="reject" onClick={() => handleApprovalPopup(cancelRequest, true)}>Reject</button>
                                                                    </>
                                                                )}
                                                                {cancelRequest.status === approvalStatus.ACCEPT && (
                                                                    <button className="accept disabled" disabled>Approved</button>
                                                                )}
                                                                {cancelRequest.status === approvalStatus.REJECT && (
                                                                    <button className="reject disabled" disabled>Rejected</button>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {show === cancelRequest.id ?
                                                        <tr className="table-row">
                                                            <td className='dropped'>
                                                                {cancelRequest.reason}
                                                            </td>
                                                        </tr>
                                                        : ""}
                                                    {showReason === cancelRequest.id ?
                                                        <tr className="table-row">
                                                            <td className='dropped'>
                                                                {cancelRequest.admin_reason}
                                                            </td>
                                                        </tr>
                                                        : ""}
                                                </>
                                            );
                                        })
                                        :
                                        <NoRecord />
                                    }
                                </tbody>
                            }
                        </table>
                    </div>
                    <div>
                        {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
                    </div>
                </div>
                <ApprovalPopup popup={popup} _popup={_popup} requestedBy={requestedById} requestId={requestId} _approved={_approved} isReject={isReject} initialRefundAmount={refundAmount}/>
            </HotdealStyleWrapper>
        </>
    )
}

export default CancelReservationRequest