import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import copy from "../../../assets/Images/icons/faLink.svg";
import edit from "../../../assets/Images/icons/faEdit.svg";
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { PlanDescriptionWrapper } from "../../Plan/PlanDescription/style/planDescription";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { planType } from "../../../config/constant";
import Switch from "react-switch";

const CreditPlanDetails = () => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const [plan, _plan] = useState()
    const [prices, _prices] = useState([])
    const [editdetails, _editdetails] = useState(false)
    const [loading, _loading] = useState(false)

    // Field Values
    const [name, _name] = useState('')
    const [nickname, _nickname] = useState('')
    const [status, _status] = useState(true)
    const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
    const planAccess = userAccess.find(o => o.slug === "plans")
    const { planId } = useParams()
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate()

    const handleSwitch = () => {
        setChecked((prev)=>!prev);
    };

    useEffect(() => {
        getPlans()
    }, [])

    const setFieldValues = (values) => {
        _name(values.name)
        _status(values.active)
        _nickname(values.prices[0].nickname)
        setChecked(values.prices[0].is_popular)
    }

    const getPlans = () => {
        API.get(APP_URLS.VIEW_PLAN + `/${planId}`)
            .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                    _plan(resp.data.item)
                    setFieldValues(resp.data.item)
                    _prices(resp.data.item.prices)
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                    if (resp.data.data.message) {
                        error_message = resp.data.data.message
                    } else {
                        {
                            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                        }
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message;
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    }

    const handleSave = () => {
        let data = {}
        data['name'] = name
        data['nickname'] = nickname
        data['active'] = status
        data['type'] = planType.ONE_TIME
        data['is_popular'] = checked ? 1 : 0
        if (!name.trim()) {
            toast.error("Credit plan name is required", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else if (name.length < 2 || name.length > 50) {
            toast.error("Credit plan name must be between 2 and 50 characters", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else if (!nickname.trim()) {
            toast.error("Nickname is required", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else if (nickname.length < 2 || nickname.length > 50) {
            toast.error("Nickname must be between 2 and 50 characters", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
        _loading(true);
            API.post(APP_URLS.UPDATE_PLAN + `/${planId}?_method=PATCH`, data)
                .then((res) => {
                    _loading(false)
                    const resp = res.data;
                    if (resp.success === true) {
                        toast.success(resp.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        _editdetails(false)
                        getPlans()
                    } else {
                        toast.error(resp.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch(function (error) {
                    _loading(false)
                    const resp = error.response;
                    let error_message = "";
                    if (resp.data.data !== undefined && resp.data.data !== null) {
                        if (resp.data.data.message) {
                            error_message = resp.data.data.message
                        } else {
                            {
                                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                            }
                        }
                    } else if (resp.data.data?.error !== undefined) {
                        error_message = resp.data.data.error;
                    } else if (resp.data?.error !== undefined) {
                        error_message = resp.data.error;
                    } else {
                        error_message = resp.data.message;
                    }
                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
    }


    return (
        <>
            <PlanDescriptionWrapper>
                <div className="auctionMainContainer">
                    <div className="row mt-4">
                        <div className="col-md-4 d-flex align-items-center">
                            <img src={back} className="ms-4 backbtn" onClick={() => navigate("/credits?" +
                                (query.get("keyword") !== null
                                    ? "keyword=" + query.get("keyword") + "&"
                                    : "") +
                                (query.get("trashed") !== null
                                    ? "trashed=" + query.get("trashed") + "&"
                                    : "") +
                                (query.get("per_page") !== null
                                    ? "per_page=" + query.get("per_page") + "&"
                                    : "") +
                                (query.get("page") !== null
                                    ? "page=" + query.get("page")
                                    : "")
                            )} />
                        </div>
                        <div className="col-md-4">
                            <div className="heading">
                                <h1>CREDIT PLAN DETAILS</h1>
                                <h5 className="mt-2">
                                    {plan?.stripe_product_id}
                                    <CopyToClipboard text={plan?.stripe_product_id}>
                                        <img src={copy} onClick={() => toast("Text Copied")} />
                                    </CopyToClipboard>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="edit-btn">
                        <h5>Details</h5>
                        {planAccess.permissions.find((o) => o.slug === "update") ?
                            plan?.deleted_at === "" || plan?.deleted_at === null ?
                                editdetails === false ?
                                    <button className="btn-link" onClick={() => _editdetails(true)}><img src={edit} alt="" /> Edit </button>
                                    :
                                    <>
                                        <div className="d-flex">
                                            <button className={(loading ? "disabled" : "") + " accept"} onClick={handleSave} disabled={loading}>Save</button>
                                            <button className={(loading ? "disabled" : "") + " reject ms-2"} onClick={() => {
                                                _editdetails(false)
                                                setFieldValues(plan)
                                            }}>Cancel</button>
                                        </div>
                                    </>
                                : ''
                            : ''}

                    </div>
                    <hr className="hr-line" />

                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="details">
                                    <div className="details-name">
                                        <h5>Name</h5>
                                        {editdetails ?
                                            <input type="text" className="form-control" value={name} onChange={(e) => _name(e.target.value)} /> :
                                            <h6>{plan?.name}</h6>}
                                    </div>
                                    <div className="details-name">
                                        <h5>Status</h5>
                                        {editdetails ?
                                            <select name="status" id="status" className="form-control" value={status} onChange={(e) => _status(e.target.value)} >
                                                <option value='1'>Active</option>
                                                <option value='0'>Inactive</option>
                                            </select> :
                                            <h6>{plan?.active === 1 ? 'Active' : 'Inactive'}</h6>}
                                    </div>
                                    <div className="details-name formInput">
                                        <label className="fieldLabel mb-2">Is Popular</label>
                                        <Switch
                                            name="is_popular"
                                            onChange={handleSwitch}
                                            checked={checked}
                                            disabled={!editdetails ? true : false}
                                            className="react-switch"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            offColor="#D9D9D9"
                                            onColor="#7589EC"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="details-name">
                                    <h5>Nickname</h5>
                                    {editdetails ?
                                        <input type="text" className="form-control" value={nickname} onChange={(e) => _nickname(e.target.value)} /> :
                                        <h6>{plan?.prices[0].nickname}</h6>}
                                </div>
                                <div className="details-name">
                                    <h5>Created</h5>
                                    <h6>{moment(plan?.updated_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tables">
                        <div>
                            <div className="features">
                                <h5>Pricing</h5>
                            </div>
                            <div className="tableContainer">
                                <table className="auctionTable">
                                    <thead className="tableHead">
                                        <tr className="table-row">
                                            <th>ID</th>
                                            <th>PRICE STRIPE ID</th>
                                            <th className="pricedesc">PRICE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {prices.length > 0 ?
                                            prices.map((item, index) => {
                                                return <tr className="table-row" key={index}>
                                                    <td>{item.id}</td>
                                                    <td className="clipboard">
                                                        <div className="my-2 d-flex justify-content-center"><span className="copyText">{item.stripe_price_id}</span>
                                                            <CopyToClipboard text={item.stripe_price_id}>
                                                                <img src={copy} alt="" onClick={() => toast("Text Copied")} />
                                                            </CopyToClipboard>
                                                        </div>
                                                    </td>
                                                    <td className="pricedesc">
                                                        <div>
                                                            <p>€ {new Intl.NumberFormat("de-DE", { currency: 'EUR' }).format((item.unit_amount / 100))}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }) :
                                            <NoRecord />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </PlanDescriptionWrapper>
        </>
    );
};

export default CreditPlanDetails;
