import styled from "styled-components";
import prev from "../../../Images/icons/svgIcon/previous-icon.svg"
import next from "../../../Images/icons/svgIcon/next-icon.svg"
export const PaginationWrapper = styled.div`

.pagination-outer {
    padding: 0;
    margin-top: 20px;
    margin-right: 25px;
    float: right;
    list-style-type: none;
}
.pagination-outer li {
    float: left;
    margin-left: 5px;
}
.page-link.prev, .page-link.next{
    width: auto;
    border: none;
}
.prev {
    border-radius: 3px 0 0 3px;
    font-weight: 600;
}
a.page-prev-link:before {
    content: '';
    background: url(${prev});
    background-size: 20px;
    position: absolute;
    width: 20px;
    height: 8px;
    background-repeat: no-repeat;
    margin: 11px 5px;
    margin-left: -23px;
}
.next{
    border-radius: 0 3px 3px 0;
    margin-left: -1px;
    font-weight: 600;
}
a.page-next-link:after {
    content: '';
    background: url(${next});
    background-size: 20px;
    position: absolute;
    width: 20px;
    height: 8px;
    background-repeat: no-repeat;
    margin: 11px 5px;
}
.page-link {
    border: none;
    color: #607afc;
    border-radius: 50%;
    background: #fff;
    height: 35px;
    min-width: 35px;
    width: auto;
    line-height: 28px;
    text-align: center;
    padding: 3px;
    border: 1px solid #607afc;
}
.page-link a{
    text-decoration: none;
    color: #607afc;
}
.page-item.active-page a {
    background: #607afc;
    color: #ffffff;
}
.page-item.break-page .page-link {
    line-height: 20px;
}

@media (max-width: 480px){
    .pagination-outer {
        margin-right: 0;
    }
    .page-link {
        height: 30px;
        min-width: 30px;
        width: auto;
        line-height: 24px;
        font-size: 13px;
    }
    a.page-prev-link:before {
        background-size: 15px;
        width: 15px;
        height: 8px;
        margin: 8px 0px;
        margin-left: -17px;
    }
    a.page-next-link:after {
        background-size: 15px;
        width: 15px;
        height: 8px;
        margin: 9px 0px;
    }
    .page-item.break-page .page-link {
        line-height: 17px;
    }
}


`