import React, { useEffect, useState } from 'react'
import Menu from '../SharedComponents/Menu'
import ham from "../Images/icons/svgIcon/hamburger.svg"
import useWindowDimensions from "../Screen Size";

const MainLayout = (props) => {
  const [hamburger, sethamburger] = useState(false);
  const [auctionMenu, _auctionMenu] = useState(false);
  const [hotdealsMenu, _hotdealsMenu] = useState(false);
  const [profileMenu, _profileMenu] = useState(false);
  const [priceMenu, _priceMenu] = useState(false);
  const [termsMenu, _termsMenu] = useState(false);
  const { height, width } = useWindowDimensions(); 
  // useEffect(() => {
  //   let authToken=localStorage.getItem("token")
  //   if(!authToken){
  //     window.location.reload();
  //   }
  // }, [])
  const handleMenu = () => {
    sethamburger(true);
  };
  return (
    <>
      <div className="mainContainer">
        <div className="container-fluid">
          <div className="menu">
            {width < 768 ? (
              <div className="hamburger" onClick={handleMenu}>
                <div className={hamburger ? "menuOpen" : "menuClose"}>
                  <Menu
                    sethamburger={sethamburger}
                    hamburger={hamburger}
                    auctionMenu={auctionMenu}
                    _auctionMenu={_auctionMenu}
                    hotdealsMenu={hotdealsMenu}
                    _hotdealsMenu={_hotdealsMenu}
                    profileMenu={profileMenu}
                    _profileMenu={_profileMenu}
                    priceMenu={priceMenu}
                    _priceMenu={_priceMenu}
                    termsMenu={termsMenu} 
                    _termsMenu={_termsMenu}
                  />
                </div>
                <img className={(hamburger ? "d-none" : "") + " menuIcon"} src={ham} onClick={handleMenu} />
              </div>
            ) : (
              <div className="leftMenu">
                <Menu 
                  auctionMenu={auctionMenu} 
                  _auctionMenu={_auctionMenu} 
                  hotdealsMenu={hotdealsMenu}
                  _hotdealsMenu={_hotdealsMenu}
                  profileMenu={profileMenu} 
                  _profileMenu={_profileMenu} 
                  priceMenu={priceMenu} 
                  _priceMenu={_priceMenu}
                  termsMenu={termsMenu} 
                  _termsMenu={_termsMenu}
                />
              </div>
            )}
          </div>
          <div
            className="mainContent"
            onClick={() => {
              _auctionMenu(false);
              _hotdealsMenu(false);
              _profileMenu(false);
              _termsMenu(false)
              _priceMenu(false); 
              sethamburger(false);
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout