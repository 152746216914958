import React, { useState, useContext, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import { PaginationWrapper } from "./style/PaginationWrapper";

const Pagination = (props) => {
  const handlePageClick = (event) => {
    props.onPageClick(event.selected)
    window.scrollTo(0, 0);
  };

  
  return (
  <PaginationWrapper>
    <nav className='Page navigation '>
      <ReactPaginate
              previousLabel="PREV"
              nextLabel="NEXT"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-link prev"
              previousLinkClassName="page-prev-link"
              nextClassName="page-link next"
              nextLinkClassName="page-next-link"
              // breakLabel="..."
              breakClassName="page-item break-page"
              breakLinkClassName="page-link"
              pageCount={props.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              containerClassName="pagination-outer"
              activeClassName="active-page"
              initialPage={props.page?props.page - 1:0}
              onPageChange={handlePageClick}
            />
      </nav>
      </PaginationWrapper>
  );
};

export default Pagination;