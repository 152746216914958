import React, { useEffect, useState } from 'react'
import { APP_URLS } from '../../../api/url';
import API from '../../../api/axios';
import SearchableObjDropdownWithImage from '../SearchableDropdown/SearchableObjDropdownWithImage';
import { toast } from 'react-toastify'; 
import { DEBOUNCE_TIME, userTypeName } from '../../../config/constant';

function SellerCustomersFilter({seller , setSeller, filters, _filters, searchSubKeyword, _searchSubKeyword, type}) {  
    const [customers, _customers] = useState([])  
    const [customerName,_customerName] = useState('') 

    //debounce effect when searching
    useEffect(() => {
        const timer = setTimeout(() => { 
            if(customerName !== undefined && customerName !== null)
            getAllCustomers();
        }, DEBOUNCE_TIME);
        return () => clearTimeout(timer); 
    }, [customerName]) 

    //fetching all customers including private and pro
    const getAllCustomers = () => {
        API.get(`${APP_URLS.ALL_CUSTOMER}?keyword=${customerName}&per_page=20`)
            .then((res) => {
                const resp = res.data;
                if (res.status === 200) {
                    _customers(resp.data)
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message || 'An unknown error occurred';
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };
    return <SearchableObjDropdownWithImage _filters={_filters} filters={filters} name={`sellerCustomer`} className="filterBox" setChangeValue={(val)=>setSeller(val)} val={seller} placeholder={`Select ${type}`} dropdownData={customers} func={_customerName} searchkeyword={searchSubKeyword} _searchkeyword={_searchSubKeyword} allData={true} />
}

export default SellerCustomersFilter
