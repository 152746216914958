import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchWrapper } from "./searchablestyle";

const SearchableObjDropdownWithImage = (props) => {  
  const [countries,_countries] = useState( ()=> JSON.parse(localStorage.getItem("countries"))) 
  const [filteredData, _filteredData] = useState([]); 

  useEffect(() => { 
    if (
      props.dropdownData &&
      (props.disabled !== true || props.closed === true)
    ) {
      const newData = props.dropdownData.map((element) =>   ({
              label: props.searchByPseudoName ? element.pseudo : element.name,
              value: element.id,
              image: element?.flag || element?.customer_limited_address?.flag_thumbnail,
              countryId : element?.country_id || element?.customer_limited_address?.country_id,
              source: element?.source
            })
          );
      _filteredData(newData)
    }
  }, [props.dropdownData]); 
 

  useEffect(()=>{
    if(props.filters){
      props._filters(false)
    }
  },[props.filters])
 

  return (
    <SearchWrapper>
      <Autocomplete 
        disableClearable 
        key={props.filters} 
        disabled={props.disabled} 
        className={props.className}
        value={props.searchkeyword}
        options={filteredData} 
        filterOptions={(options, state) => options} //Disabling MUI's default searchable option
        onInputChange={(x) => {
          if (props.func && x?.target?.value) { 
            props.func(x.target.value);
          }else{
            props.func('');
          }
        }}
        sx={{ width: 250,
          '.MuiIconButton-root':{
            display:props.searchByPseudoName ? 'none' : ''
          }
         }}
        freeSolo
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.value}>
              <div className="dropdownList">
                <img className="countryflag text-end me-2" src={option.image} title={countries.find((o)=>o.id==option.countryId)?.fullname}/>
                <span>{option.label} </span>
              </div>
            </li>
          );
        }}
        forcePopupIcon={true}
        onChange={(event, newValue) => {  
          if (typeof props._searchkeyword === 'function') {
            props._searchkeyword(newValue.label)
          }
          props.setChangeValue(newValue ? (props?.allData ? newValue : newValue.value) : ''); //we are using allData props to get all data not only id
        }}
        renderInput={(params) => { 
          return (
          <TextField
            {...params}
            onChange={(e)=>{
              if(e.target.value == ''){
                props.setChangeValue('');
              }
            }}
            placeholder={props.placeholder}
          />
        )}}
      />
    </SearchWrapper>
  );
};

export default SearchableObjDropdownWithImage;
