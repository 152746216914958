import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import waste from "../../assets/Images/image/Waste.png";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../../assets/SharedComponents/Pagination";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import NoRecord from '../../assets/SharedComponents/Table/NoRecord'
import SearchKey from "../../assets/SharedComponents/Search/SearchKey";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount"
import { TagsWrapper } from "./style/Tags";
import edit from "../../assets/Images/image/edit.png";
import faHide from "../../assets/Images/icons/svgIcon/FaHide.svg"
import { getOverflowState } from "../../core/utils";

const ForumsTable = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, _data] = useState([]);
  const [dataDeleted, _dataDeleted] = useState(false);
  const [trashed, _trashed] = useState(searchParams.get('trashed') !== null ? (searchParams.get('trashed') === 'false' ? false : true) : true);
  const [searchkeyword, _searchkeyword] = useState(query.get('keyword') !== null ? query.get('keyword') : "");
  const [countValue, _countValue] = useState(query.get('per_page') !== null ? query.get('per_page') : "");
  const [page, setPage] = useState(query.get('page') !== null ? parseInt(query.get('page')) : 1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);
  const [show, _show] = useState('');
  const [overflowStates, setOverflowStates] = useState({});
  const refs = useRef({});

  useEffect(() => {
    getForumListing() //fetching forum listing
    _dataDeleted(false) //hitting api after deleting forum tag
  }, [page, searchkeyword, dataDeleted, countValue, trashed])

  //fetching forum listing
  const getForumListing = () => {
    _loaded(true)
    API.get(APP_URLS.FORUM_TAGS + `?page=${page}&per_page=${countValue}&filter[title]=${searchkeyword}&with_trashed=${trashed === true ? 1 : 0}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data)
          _totalPage(resp.data.last_page)
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if (resp.data.data.message) {
            error_message = resp.data.data.message
          } else {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  //function for deleting forum tag
  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.FORUM_TAGS + `/${id}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Forum tag has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch((error) => {
                const resp = error.response;
                let error_message =
                  resp.data?.data?.message ||
                  resp.data?.data?.error ||
                  resp.data?.error ||
                  resp.data?.message ||
                  "An error occurred";
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  //handles search functionality for search tags
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if ((func === _countValue && searchValue !== countValue) || (func === _searchkeyword && searchValue !== searchkeyword)) {
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  //reset filter function
  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _trashed(true);
    if (searchkeyword !== "" || countValue !== '' || trashed !== true) {
      _totalPage(0)
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  //show full description in accordion
  const handleShowDescription = (id) => {
    if (show) {
      _show(id === show ? '' : id);
    } else {
      _show(id);
    }
  }

  //getting total overflow and ellipses status after the data is rendered.
  useEffect(() => {
    const totalEllipsisData = getOverflowState(refs.current)
    setOverflowStates(totalEllipsisData);
  }, [data]);

  return (
    <>
      <TagsWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 offset-md-4">
              <h1>TAGS</h1>
            </div>
          </div>
          <div className="row">
            <div className="justify-content-between mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
              <div className="col-md-4 mb-2">
                {/* {planAccess.permissions.find((o) => o.slug === "add") ? */}
                <div className="actionContainer">
                  <NavLink className="btn" to="/create-tags">+ Add</NavLink>
                </div>
                {/* :''} */}
              </div>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <SearchKey
                  onSearchClick={(search) => handleSearchChange(_searchkeyword, search)}
                  val={searchkeyword}
                  id="searchData"
                  filters={filters}
                  _filters={_filters}
                  placeholder="Search by Title"
                />
                <RecordCount
                  onSearchClick={(search) => handleSearchChange(_countValue, search)}
                  val={countValue}
                  id="pageCount"
                  filters={filters}
                  _filters={_filters} />
                <Tooltip title="Clear Filter" placement="bottom">
                  <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
              <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
            <div className="mt-1">
              <input type="checkbox" checked={!trashed} onChange={() => {
                _totalPage(0)
                setPage(1);
                _trashed(!trashed)
              }} />
              <span className="ms-2">Hide Deleted Data</span>
            </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th className="clipboard">
                    Title
                  </th>
                  <th className="longText">
                    Description
                  </th>
                  <th className="clipboard">
                    Created By
                  </th>
                  <th className="clipboard">
                    Updated By
                  </th>
                  {/* {planAccess.permissions.find((o) => o.slug === "delete") ? */}
                  <th>Action</th>
                  {/* : ""} */}
                </tr>
              </thead>
              {loaded ?
                <div className='d-flex justify-content-center three-dot'>
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
                :
                <tbody>
                  {data.length > 0 ?
                    data.map((item) => {
                      const description = item?.description?.['en']
                      return (
                        <>
                          <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={item.id}>
                            <td>{item.title['en']}</td>
                            <td className="longText">
                              {item?.description ?
                                <div className="d-flex flex-row align-items-center justify-content-center">
                                  <span
                                    className="message-text"
                                    ref={(el) => (refs.current[item.id] = el)}
                                    title={description}>{description || '-'}
                                  </span>
                                  {overflowStates[item.id] ? <img src={faHide} alt="show" className={(show == item?.id ? "close" : "open") + " dropbtn ms-2"} onClick={() => handleShowDescription(item?.id)} /> : null}
                                </div>
                                : "-"}
                            </td>
                            <td>{item.create_activity_log?.first_name || '-'}</td>
                            <td>{item.update_activity_log?.first_name || '-'}</td>
                            <td>
                              {!item.deleted_at ?
                                <>
                                  {/* {faqAccess.permissions.find((o) => o.slug === "update") ? ( */}
                                  <NavLink to={`/edit-tags/${item.id}?` + (countValue !== "" ? "per_page=" + countValue + "&" : "") + (trashed !== true ? "trashed=" + trashed + "&" : "") + (page !== 1 ? "page=" + page : "")}>
                                    <img src={edit} alt="" className="mx-2" role="button" />
                                  </NavLink>
                                  {/* ) : (
                                ""
                              )} */}
                                  {/* {faqAccess.permissions.find((o) => o.slug === "delete") ?  */}
                                  <img src={waste} alt="Delete" role="button" onClick={() => handleDelete(item.id)} />
                                  {/* : ""} */}
                                </>
                                : null}
                            </td>
                          </tr>
                          {show === item?.id ?
                            <tr className="table-row">
                              <td className='dropped'>
                                {item?.description?.['en']}
                              </td>
                            </tr>
                            : ""}
                        </>
                      )
                    })
                    :
                    <NoRecord />
                  }
                </tbody>
              }
            </table>
          </div>
          <div>
            {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
          </div>
        </div>
      </TagsWrapper>
    </>
  );
};

export default ForumsTable;
