import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import copy from "../../../../assets/Images/icons/faLink.svg";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";

const PriceTable = ({ prices }) => {
  return (
    <>
            <div className="tableContainer">
              <table className="auctionTable">
                  <thead className="tableHead">
                      <tr className="table-row">
                        <th>ID</th>
                        <th>PRICE STRIPE ID</th>
                        <th className="pricedesc">PRICE</th>
                        <th>{('Recurring Interval').toUpperCase()}</th>
                        <th>{('Recurring Interval Count').toUpperCase()}</th>
                      </tr>
                  </thead>
                  <tbody>
                    {prices.length>0?
                    prices.map((item,index)=>{
                      return <tr className="table-row" key={index}>
                        <td>{item.id}</td>
                        <td className="clipboard">
                          <div className="my-2 d-flex justify-content-center"><span className="copyText">{item.stripe_price_id}</span>
                            <CopyToClipboard text={item.stripe_price_id}>
                              <img src={copy} alt="" onClick={() => toast("Text Copied")}/>
                            </CopyToClipboard>
                          </div>
                        </td>
                        <td className="pricedesc">
                          <div>
                            <p>€ {new Intl.NumberFormat("de-DE",{currency:'EUR'}).format( (item.unit_amount/100)) } / month</p>
                            <p className="priceName">{item.nickname}</p>
                          </div>
                        </td>
                        <td className="subsription">{(item.recurring_interval).charAt(0).toUpperCase()+item.recurring_interval.slice(1)}</td>
                        <td>{item.recurring_interval_count?item.recurring_interval_count:'-'}</td>
                      </tr>
                    }):
                    <NoRecord />
                    } 
                  </tbody>
              </table>
            </div>
    </>
  );
};

export default PriceTable;
